export const Avatars = [
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile1_InTxuA9Np.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652369924716",
      name: "avatar1"
    },
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile2_Zn9YXPrD8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652369926846",
      name: "avatar2"
    },
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile3_7wVDFCUrg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652460511671",
      name: "avatar3"
    },
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile4_BwhmMiMmz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652369925219",
      name: "avatar4"
    },
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile5__chMhrZvz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652369924548",
      name: "avatar5"
    },
    {
      url: "https://ik.imagekit.io/ecomdiagonalley/Social/Profile6_opJ4cMAaA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652369924530",
      name: "avatar6"
    },
  ]