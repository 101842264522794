import React from 'react';

const Error404 = () => {
  return (
    <div>
      Error404
    </div>
  )
}

export { Error404 };