import React from 'react';

const NotificationCard = () => {
  return (
    <div className="notication-card">
      
    </div>
  )
}

export { NotificationCard };
